import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from 'grommet';
import { FormNext } from 'grommet-icons';
import styled from 'styled-components';

import useMobile from '../useMobile';

const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  transition: opacity 0.3s ease-out;
  opacity: ${(p) => (p.active ? 1 : 0.5)};
`;

const CalcContinueButtons = ({
  selectedProducts,
  handleContinue,
  handleSkipToCheckout,
  calculateOnly,
}) => {
  const isMobile = useMobile();
  if (!isMobile) {
    return null;
  }
  return (
    <Container
      justify="stretch"
      background="transparent"
      direction="row"
      active={selectedProducts.length > 0}
      border={{ side: 'top', size: 'small', color: 'black' }}
    >
      <Box flex="grow">
        <Button
          primary={calculateOnly}
          fill
          reverse
          label="Calculate"
          onClick={handleContinue}
          style={calculateOnly ? { flexBasis: '4rem' } : {}}
          disabled={selectedProducts.length === 0}
          icon={calculateOnly ? <FormNext /> : undefined}
        />
      </Box>
      {!calculateOnly && (
        <Box flex="grow">
          <Button
            onClick={handleSkipToCheckout}
            primary
            reverse
            fill
            disabled={selectedProducts.length === 0}
            label="Checkout"
            icon={<FormNext />}
          />
        </Box>
      )}
    </Container>
  );
};

CalcContinueButtons.propTypes = {
  selectedProducts: PropTypes.array,
  handleContinue: PropTypes.func,
  handleSkipToCheckout: PropTypes.func,
  calculateOnly: PropTypes.bool,
};

export default CalcContinueButtons;
