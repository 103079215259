import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import CustomerSampleOrder from './CustomerSampleOrder';

const CustomerSampleOrders = ({
  orders,
  onSelect,
  selectedProductIds,
  sampledProductIds,
  paintCollections,
  colCount,
  ...rest
}) => {
  return (
    <Box {...rest}>
      {orders.map((x) => (
        <CustomerSampleOrder
          key={x.id}
          {...x}
          onSelect={onSelect}
          selectedProductIds={selectedProductIds}
          sampledProductIds={sampledProductIds}
          colCount={colCount}
          paintCollections={paintCollections}
        />
      ))}
    </Box>
  );
};

CustomerSampleOrders.propTypes = {
  orders: PropTypes.array,
  onSelect: PropTypes.func,
  selectedProductIds: PropTypes.array,
  sampledProductIds: PropTypes.array,
  colCount: PropTypes.number,
  paintCollections: PropTypes.array,
};

export default memo(CustomerSampleOrders);
