import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { intersection } from '../../lib/nodash';
import { Box, Text, Grid } from 'grommet';
import { format } from 'date-fns';

import CalcBrowsePaintCollectionsItem from '../Calculator/CalcBrowsePaintCollectionsItem';
import useMobile from '../useMobile';

const CustomerSampleOrder = ({
  createdAt,
  products,
  onSelect,
  selectedProductIds,
  paintCollections,
  colCount,
}) => {
  const isMobile = useMobile();
  const productIds = products.map((x) => x.id);
  const sampledPaintCollections = useMemo(
    () =>
      paintCollections.filter((c) => {
        return (
          intersection(
            productIds,
            c.products.map((p) => p.shopifyId)
          ).length > 0
        );
      }),
    [productIds, paintCollections]
  );

  return (
    <Box>
      <Text
        weight={500}
        style={{ textTransform: 'uppercase', fontSize: '13px' }}
      >
        Order placed: {format(new Date(createdAt), 'M/d/yy')}
      </Text>
      <Grid columns={{ count: colCount, size: 'auto' }} gap="small">
        {sampledPaintCollections.map((collection) => {
          const isSelected = selectedProductIds.indexOf(collection.handle) > -1;
          return (
            <CalcBrowsePaintCollectionsItem
              key={collection.id}
              paintCollection={collection}
              isSelected={isSelected}
              onSelect={onSelect}
              srcSetSizes={isMobile ? '90vw' : '15vw'}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

CustomerSampleOrder.propTypes = {
  createdAt: PropTypes.string,
  products: PropTypes.array,
  onSelect: PropTypes.func,
  selectedProductIds: PropTypes.array,
  sampledProductIds: PropTypes.array,
  paintCollections: PropTypes.array,
  colCount: PropTypes.number,
  colors: PropTypes.array,
};

export default memo(CustomerSampleOrder);
