import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  get,
  includes,
  reject,
  isEqual,
  concat,
  pick,
  map,
  flatten,
  values as _values,
} from '../../lib/nodash';
import { Formik } from 'formik';
import { Box, Button, Grid, ResponsiveContext } from 'grommet';
import { FormNext } from 'grommet-icons';
import { LazyMotion, m } from 'framer-motion';
import styled from 'styled-components';

import CalcContinueButtons from './CalcContinueButtons';
import CalcChooseIntro from './CalcChooseIntro';
import CustomerSampleOrders from '../Orders/CustomerSampleOrders';
import CalcBrowsePaintCollectionsItem from './CalcBrowsePaintCollectionsItem';
import CalcBrowsePaintCollections from './CalcBrowsePaintCollections';
import useMobile from '../useMobile';
import useInitialProductValuesForPaintCollections from '../Paint/useInitialProductValuesForPaintCollections';

const loadFeatures = () =>
  import('../../lib/framer/motionFeatures.js').then((res) => res.default);

const BrowseContinueContainer = styled(Box)`
  position: fixed;
  bottom: 30px;
  right: 30px;
`;

const CalcChoose = (props) => {
  const {
    sampleOrders,
    onContinue,
    ordersToken,
    customerName,
    browseAllPaint = false,
    searchOptions,
    filterGroups,
    handleClose,
    paintCollections = [],
    paintCollectionArrangement = [],
    selectedProducts,
    colorWheel,
  } = props;
  const isMobile = useMobile();
  const size = useContext(ResponsiveContext);
  const colCount = size === 'large' ? 3 : size === 'medium' ? 2 : 1;
  const sampledProductIds = map('id', flatten(map('products', sampleOrders)));

  const hasSuggestions =
    isMobile && (ordersToken || paintCollections.length > 0);

  const initialProductValues = useInitialProductValuesForPaintCollections(
    paintCollections,
    selectedProducts,
    sampledProductIds
  );

  return (
    <LazyMotion strict features={loadFeatures}>
      <Formik
        initialValues={{
          products: initialProductValues,
          selectedPaintCollections: [],
          skipToCheckout: false,
        }}
        onSubmit={(values) => {
          const selected = _values(
            pick(values.selectedPaintCollections, values.products)
          );
          onContinue(selected, values.skipToCheckout);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          const handleSelection = (paintCollection) => {
            const newIds = includes(
              get('handle', paintCollection),
              values.selectedPaintCollections
            )
              ? reject(
                  (x) => isEqual(x, get('handle', paintCollection)),
                  values.selectedPaintCollections
                )
              : concat(
                  get('handle', paintCollection),
                  values.selectedPaintCollections
                );
            setFieldValue('selectedPaintCollections', newIds);
          };

          const handleSkipToCheckout = () => {
            setFieldValue('skipToCheckout', true);
            handleSubmit();
          };

          return (
            <Box style={{ position: 'relative' }}>
              <CalcContinueButtons
                selectedProducts={values.selectedPaintCollections}
                handleContinue={handleSubmit}
                handleSkipToCheckout={handleSkipToCheckout}
                calculateOnly={browseAllPaint && !ordersToken}
              />
              <Box
                className="cpage__block cpage__block--landing"
                id="cpage__landing"
                direction="row-responsive"
                gap="small"
              >
                {!browseAllPaint && (
                  <Box
                    basis="40%"
                    width={{ max: '500px' }}
                    flex={{ shrink: 1, grow: 0 }}
                    pad="large"
                    border={
                      isMobile
                        ? undefined
                        : { side: 'right', size: 'small', color: 'black' }
                    }
                  >
                    <CalcChooseIntro
                      customerName={customerName}
                      canContinue={values.selectedPaintCollections.length > 0}
                      isEmpty={paintCollections.length === 0}
                      handleContinue={handleSubmit}
                      handleSkip={handleSkipToCheckout}
                      selectedProducts={values.selectedProducts}
                    />
                  </Box>
                )}
                <Box
                  basis={isMobile ? 'auto' : '40%'}
                  flex={{ grow: 1, shrink: 1 }}
                  className="cpage__grid"
                  border={
                    hasSuggestions
                      ? { side: 'top', size: 'small', color: 'black' }
                      : undefined
                  }
                >
                  {browseAllPaint ? (
                    <CalcBrowsePaintCollections
                      onSelect={handleSelection}
                      handleContinue={handleSubmit}
                      title="Choose Your Paint to Calculate"
                      handleClose={handleClose}
                      selectedPaintCollections={values.selectedPaintCollections}
                      searchOptions={searchOptions}
                      filterGroups={filterGroups}
                      paintCollections={paintCollections}
                      paintCollectionArrangement={paintCollectionArrangement}
                      colorWheel={colorWheel}
                    />
                  ) : (
                    <>
                      {sampleOrders.length ? (
                        <Box pad={isMobile ? 'medium' : undefined}>
                          <CustomerSampleOrders
                            colCount={colCount}
                            orders={sampleOrders}
                            onSelect={handleSelection}
                            selectedProductIds={values.selectedPaintCollections}
                            sampledProductIds={sampledProductIds}
                            paintCollections={paintCollections}
                            gap="large"
                          />
                        </Box>
                      ) : (
                        <Box fill="horizontal" pad="medium">
                          <Grid
                            columns={{ count: colCount, size: 'auto' }}
                            gap="small"
                          >
                            {paintCollections.map((collection) => {
                              const isSelected =
                                values.selectedPaintCollections.indexOf(
                                  collection.handle
                                ) > -1;
                              return (
                                <CalcBrowsePaintCollectionsItem
                                  key={collection.id}
                                  paintCollection={collection}
                                  isSelected={isSelected}
                                  onSelect={handleSelection}
                                />
                              );
                            })}
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
              {browseAllPaint && (
                <BrowseContinueContainer className="form-controls">
                  {!isMobile && (
                    <m.div
                      animate={{
                        opacity:
                          values.selectedPaintCollections.length === 0 ? 0 : 1,
                        scale:
                          values.selectedPaintCollections.length === 0
                            ? 0.1
                            : 1,
                      }}
                      transition={{
                        type: 'spring',
                        bounce: 0.2,
                        duration: 0.3,
                      }}
                      initial={{ opacity: 0 }}
                    >
                      <Button
                        disabled={values.selectedPaintCollections.length === 0}
                        primary
                        onClick={handleSubmit}
                        label={`Continue${
                          values.selectedPaintCollections.length > 0
                            ? ` (${values.selectedPaintCollections.length})`
                            : ''
                        }`}
                        icon={<FormNext />}
                        reverse
                      />
                    </m.div>
                  )}
                </BrowseContinueContainer>
              )}
            </Box>
          );
        }}
      </Formik>
    </LazyMotion>
  );
};

CalcChoose.propTypes = {
  initialProductGroups: PropTypes.array,
  sampleOrders: PropTypes.array,
  selectedProductIds: PropTypes.array,
  ordersToken: PropTypes.string,
  customerName: PropTypes.string,
  browseAllPaint: PropTypes.bool,
  featuredFilters: PropTypes.array,
  colorFilters: PropTypes.array,
  onContinue: PropTypes.func.isRequired,
  filterGroups: PropTypes.array,
  searchOptions: PropTypes.array,
  handleClose: PropTypes.func,
  paintCollections: PropTypes.array.isRequired,
  paintCollectionArrangement: PropTypes.array,
  selectedProducts: PropTypes.object,
  colorWheel: PropTypes.object,
};

export default memo(CalcChoose);
