import React, { useRef, useContext, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Grid, ResponsiveContext } from 'grommet';
import { m, LazyMotion } from 'framer-motion';
import styled from 'styled-components';
import StickyBox from 'react-sticky-box';

import ColorFilterPanel from '../Color/ColorFilterPanel';
import CalcBrowsePaintCollectionsItem from './CalcBrowsePaintCollectionsItem';
import makeSelectFilteredPaintCollections from '../../state/product/makeSelectFilteredPaintCollections';

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

const FilterContainer = styled(Box)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    width: 20vw;
    max-width: 230px;
    min-width: 180px;
    border-right: ${(p) => p.theme.global.borderSize.small} solid;
    border-top: ${(p) => p.theme.global.borderSize.small} solid;
  }
`;

const CalcBrowsePaintCollections = ({
  onSelect,
  searchOptions,
  filterGroups,
  selectedPaintCollections,
  paintCollectionArrangement,
  paintCollections = [],
  colorWheel,
}) => {
  const viewportSize = useContext(ResponsiveContext);
  const colCount =
    viewportSize === 'large'
      ? 4
      : viewportSize === 'medium'
      ? 3
      : viewportSize === 'medsmall'
      ? 2
      : viewportSize === 'small'
      ? 1
      : 1;
  const filterRef = useRef(null);

  const selectFiltered = useMemo(makeSelectFilteredPaintCollections, []);
  const filteredPaintCollections = useSelector((state) =>
    selectFiltered(state, {
      paintCollectionArrangement,
      paintCollections,
      colorWheel,
    })
  );

  return (
    <LazyMotion strict features={loadFeatures}>
      <Box
        fill="horizontal"
        pad={{ vertical: 'medium' }}
        direction="row-responsive"
      >
        <FilterContainer>
          <StickyBox offsetTop={20} offsetBottom={20}>
            <ColorFilterPanel
              searchOptions={searchOptions}
              filterGroups={filterGroups}
              disableZoom={true}
            />
          </StickyBox>
        </FilterContainer>
        <Box flex={true} pad={'medium'} ref={filterRef}>
          <Grid columns={{ count: colCount, size: 'auto' }} gap="small">
            {filteredPaintCollections.map((collection) => {
              const isSelected =
                selectedPaintCollections.indexOf(collection.handle) > -1;
              return (
                <m.div
                  key={collection.id}
                  layout="position"
                  transition={{ duration: 0.4 }}
                  animate={{ opacity: [0, 1] }}
                  initial={{ opacity: 0 }}
                >
                  <CalcBrowsePaintCollectionsItem
                    key={collection.id}
                    paintCollection={collection}
                    isSelected={isSelected}
                    onSelect={onSelect}
                  />
                </m.div>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </LazyMotion>
  );
};

CalcBrowsePaintCollections.propTypes = {
  handleContinue: PropTypes.func,
  onSelect: PropTypes.func,
  featuredFilters: PropTypes.array,
  colorFilters: PropTypes.array,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  selectedPaintCollections: PropTypes.array,
  filterCollections: PropTypes.array,
  searchOptions: PropTypes.array,
  filterGroups: PropTypes.array,
  colors: PropTypes.array,
  paintCollections: PropTypes.array,
  paintCollectionArrangement: PropTypes.array,
  colorWheel: PropTypes.object,
};

export default memo(CalcBrowsePaintCollections);
