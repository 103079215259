import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button, Box, Heading } from 'grommet';
import { FormNextLink } from 'grommet-icons';

import useMobile from '../useMobile';

const CalcChooseIntro = ({
  isEmpty,
  customerName = 'There',
  canContinue,
  handleContinue,
  handleSkip,
}) => {
  const isMobile = useMobile();
  const elClassName = 'cpage__text-inner';
  const elId = isEmpty ? 'cpage-text-empty' : 'cpage-text';

  return (
    <Box
      pad={isMobile ? 'medium' : { bottom: 'large' }}
      className={elClassName}
      id={elId}
      style={{ position: 'sticky', top: '105px' }}
    >
      <Heading
        level={1}
        size={isMobile ? '27px' : '45px'}
        margin={{ bottom: 'medium' }}
      >
        {canContinue
          ? 'Good Choice.'
          : `Hey ${customerName ? customerName : 'there'},`}
      </Heading>
      {canContinue && !isMobile ? (
        <>
          <Paragraph
            style={{ fontWeight: 500 }}
            margin={{ top: 'medium', bottom: 'small' }}
          >
            Already know how much paint you need?
          </Paragraph>
          <Paragraph margin={{ bottom: 'medium' }}>
            Great! You&apos;ll enter quantities at checkout.
          </Paragraph>

          <Box width={{ max: '312px' }}>
            <Button
              primary
              label="Checkout"
              icon={<FormNextLink />}
              reverse
              onClick={handleSkip}
            />
          </Box>

          <Paragraph
            style={{ fontWeight: 500 }}
            margin={{ top: 'xlarge', bottom: 'small' }}
          >
            Not sure how much paint to order?
          </Paragraph>
          <Paragraph margin={{ bottom: 'medium' }}>
            No problem! Our calculator helps determine how many gallons you need
            for your space.
          </Paragraph>

          <Box width={{ max: '312px' }}>
            <Button
              primary
              width={{ max: '312px' }}
              label="Calculate Paint"
              icon={<FormNextLink />}
              reverse
              onClick={handleContinue}
            />
          </Box>
        </>
      ) : (
        <>
          <Paragraph>
            We&apos;re excited you found some colors you like. Let&apos;s get
            started.
          </Paragraph>
          <Paragraph style={{ fontWeight: 500 }} margin={{ top: 'medium' }}>
            First, choose your color(s){isMobile ? '' : ' on the right'}.
          </Paragraph>
        </>
      )}
    </Box>
  );
};

CalcChooseIntro.propTypes = {
  isEmpty: PropTypes.bool,
  customerName: PropTypes.string,
  canContinue: PropTypes.bool,
  handleContinue: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
};

export default memo(CalcChooseIntro);
